require("jquery-validation");
require('pause');

let isLocalUrl;
if(window.location.href.indexOf("localhost") > -1){
  isLocalUrl = 'http://smartpoint.dev.smartpointlab.com/'
} else{
  isLocalUrl = '/'
}



$(document).ready(function () {
  //get window size
  // function getWindowSize(){
  //   winwidth=document.all?document.body.clientWidth:window.innerWidth;
  //   winHeight=document.all?document.body.clientHeight:window.innerHeight;
  //   alert(winwidth+","+winHeight);
  // }
  // getWindowSize();
  //hide correct form modal
  $('.correct-form').hide();
  //hide correct form modal on outclick
  $(document).on('click', function(e){
    if($(e.target).is('.correct-form')){
      $('.correct-form').fadeOut();
    }
  })
  //hide success page
  $('.popup__success').hide();
  //close success on close button
  $('.popup__success .close').click(function(){
    $('.popup__success').hide();
  })
  //change burger img to close img
  $(".burger-item").click(function (e) {
    e.stopImmediatePropagation();
    $(this).toggleClass("active-burger");
    $(".header__burger-menu").toggleClass("active-burger");
    setTimeout(function () {
      $(".main__popup-button").toggleClass("hide");
    }, 300);
  });

  //check input type file size
  $('#file').change(function () {
    if(this.files[0].size >= 5000000){
      $('#file-size').css('color', '#eb5757');
    } else{
      $('#file-size').css('color', '#4F4F4F');
    }
  })

  //header scroll
  $(window).bind("mousewheel", function (event) {
    let header = $(".header");

    if (event.originalEvent.wheelDelta >= 0) {
      header.removeClass("header-scroll");
    } else {
      header.addClass("header-scroll");
    }
  });

  //header scroll for firefox
  $(window).bind("wheel", function (event) {
    let header = $(".header");

    if (event.originalEvent.deltaY <= 0) {
      header.removeClass("header-scroll");
    } else {
      header.addClass("header-scroll");
    }
  });

  //change approach image
  if ($(window).width() <= 768) {
    $(".approach__img img").attr("src", "/dist/img/approach3.svg");
  }

  //hide popups on esc

  if($('.main__popup').css('display') == 'flex' || $('.top-popup').css('display') == 'flex'){
    $('html, body').keyup(function(e){
      if(e.which == 27){
        $(".main__popup, body").removeClass("active-popup");
        $(".main__popup-button").show();
        $('.top-popup').removeClass("hide-scrollbar");
        $('.main__popup').removeClass("hide-scrollbar");
        $('html').removeClass("hide-scrollbar");
        $(".top-popup").removeClass("active-popup-top");
        $(".main__popup-button").show();
        $('.main__popup').removeClass("hide-scrollbar");
      }
    })
  }
  //show main popup
  $(".main__popup-button").click(function () {
    $(".top-popup").addClass("active-popup-top");
    $('.top-popup').addClass("hide-scrollbar");
    $('html').addClass("hide-scrollbar");
    $(".main__popup-button").hide();
    function getRecaptcha() {
      grecaptcha.ready(function () {
        grecaptcha
            .execute("6LdAfbgZAAAAAApISBKf2EShstlXQB-hqhzVYJaX", {
              action: "submit",
            })
            .then(function (token) {
              // Add your logic to submit to your backend server here.
              document.querySelector("#main-recaptcha").value = token;
              document.querySelector("#top-recaptcha").value = token;
            });
      });
    }
    getRecaptcha();
  });
  $(".popup__close").click(function () {
    $(".main__popup, body").removeClass("active-popup");
    $(".main__popup-button").show();
    $('.top-popup').removeClass("hide-scrollbar");
    $('.main__popup').removeClass("hide-scrollbar");
    $('html').removeClass("hide-scrollbar");
  });

  $("#main-cta").click(function () {
    $(".main__popup").addClass("active-popup");
    $("body").addClass("active-popup");
    $('.main__popup').addClass("hide-scrollbar");
    $('html').addClass("hide-scrollbar");
    $(".main__popup-button").hide();
    function getRecaptcha() {
      grecaptcha.ready(function () {
        grecaptcha
            .execute("6LdAfbgZAAAAAApISBKf2EShstlXQB-hqhzVYJaX", {
              action: "submit",
            })
            .then(function (token) {
              // Add your logic to submit to your backend server here.
              document.querySelector("#main-recaptcha").value = token;
              document.querySelector("#top-recaptcha").value = token;
            });
      });
    }
    getRecaptcha();
  });
  $("#header-cta").click(function () {
    $(".top-popup").addClass("active-popup-top");
    $(".main__popup-button").hide();
    $('.top-popup').addClass("hide-scrollbar");
    $('html').addClass("hide-scrollbar");
  });
  $("#close-top").click(function () {
    $(".top-popup").removeClass("active-popup-top");
    $(".main__popup-button").show();
    $('.main__popup').removeClass("hide-scrollbar");
    $('html').removeClass("hide-scrollbar");
  });

  //scroll down  
  $(".main-c__scroll-down").click(function () {
    let scrollDown = $(window).scrollTop();
    if(window.width >= 768){
      $("html, body").animate({ scrollTop: scrollDown + 15750 }, 100000, 'linear');
    } else{
      $("html, body").animate({ scrollTop: scrollDown + 15650 }, 100000, 'linear');
    }
  });

  $('html').click(function (){
    $('html, body').stop(true);
  });
  $(".main-c__scroll-down").click(function (e) {
    e.stopPropagation();
  })
  $('html, body').keyup(function(e){
    if(e.which == 27){
      e.stopImmediatePropagation();
      $('html, body').stop(true);
    } else if (e.which == 13){
      e.stopPropagation();
      $('html, body').stop(true);
    }
  });

  $('.main-c__scroll-down').mouseover(function() {
    $(this).find('img').attr('src', '/dist/img/arrow-down-yellow.svg');
  })
  $('.main-c__scroll-down').mouseout(function(){
    $(this).find('img').attr('src', '/dist/img/arrow-down.svg');
  });
  $('.projects__link, .projects-page__link a').mouseover(function() {
    $(this).find('img').attr('src', '/dist/img/arrow-right.svg');
  })
  $('.projects__link, .projects-page__link a').mouseout(function(){
    $(this).find('img').attr('src', '/dist/img/arrow-right-gray.svg');
  });



  //form validation
  $("#main-form").validate({
    rules: {
      company: {
        required: true,
        minlength: 2,
      },
      name: {
        required: true,
        minlength: 2,
      },
      descr: {
        required: true,
        minlength: 10,
      },
      phone: {
        required: true,
        number: true,
        minlength: 9
      }
    },
    onfocusout: function(element){
      this.element(element);
    },
    messages: {
      company: {
        required: "need a company name",
        minlength: "at least 2 chars",
      },
      descr: {
        required: "we need to know about you",
      },
      name: {
        required: "need a name",
        minlength: "at least 2 chars",
      },
      phone: {
        required: 'need a phone',
        minlength: 'at least 9 chars'
      }
    },
    onsubmit: true
  });
  //top form validation

  $("#top-form").validate({
    rules: {
      nameTop: {
        required: true,
        minlength: 2,
      },
      email: {
        required: true,
        email: true,
      },
      phoneTop: {
        required: true,
        minlength: 9,
        maxlength: 20,
        number: true,
      },
      message: {
        minlength: 5,
      },
    },
    onfocusout: function(element){
      this.element(element);
    },
    messages: {
      nameTop: {
        required: "need a name",
        minlength: "at least 2 chars",
      },
      phoneTop: {
        required: "need a phone number",
        number: "please enter a phone number",
      },
      email: {
        required: "need an email",
        minlength: "at least 2 chars",
      },
    },
    onsubmit: true
  });
  //hide input labels on focusout
  $(".top-popup__form input, .main__popup input").focusout(function () {
    if ($(this).val() !== "") {
      $("label[for='" + $(this).attr("id") + "']").hide();
    }
  });

  if ($(".main__popup").hasClass("active-popup")) {
    $(".main__popup-button").hide();
  }

  $("#top-form-send").click(function () {
    if($('#top-form').valid()){
      sendTopForm();
    } else{
      $('.correct-form').fadeIn();
    }
  });

  $("#main-form-send").click(function () {
    if($('#main-form').valid()) {
      sendMainForm();
    } else {
      $('.correct-form').fadeIn();
    }
  });

  $('input').on('focusout', function(){
    console.log('just work pls');
    if($(this).val().length > 0){
      $(this).closest('div').find('.form__label').hide();
    }
  });


});



//SEND FORM
function sendMainForm(e) {
  let data = {};
  let form = $(".form");
  let company = form.find("#company").val();
  let descr = form.find("#descr").val();
  let project = form.find("#project").val();
  let file = $("#file");
  let phone = form.find("#phone").val();
  let name = form.find("#name").val();
  let recaptcha = $("#main-recaptcha").val();

  data = new FormData();
  data.append("name", name);
  data.append("phone", phone);
  data.append("company", company);
  data.append("descr", descr);
  data.append("project", project);
  data.append("file", file.prop("files")[0]);
  data.append("recaptcha", recaptcha);

  $.ajax({
    url: isLocalUrl + 'api/sendrequest',
    method: "POST",
    dataType: "json",
    processData: false,
    contentType: false,
    enctype: "multipart/form-data",
    data: data,
    success: function (response) {
      if (response.status === "success") {
        $('.popup__success').show();
      }
    },
    error: function (textStatus) {
      console.log(textStatus);
    },
  });
}

function sendTopForm() {
  let data = {};
  let form = $(".form");
  let phone = form.find("#phone-top").val();
  let name = form.find("#name-top").val();
  let message = form.find("#message").val();
  let email = form.find("#email").val();
  let recaptcha = $("#top-recaptcha").val();

  data = new FormData();
  data.append("name", name);
  data.append("phone", phone);
  data.append("message", message);
  data.append("email", email);
  data.append("recaptcha", recaptcha);

  $.ajax({
    url: isLocalUrl + 'api/sendrequest',
    method: "POST",
    dataType: "json",
    processData: false,
    contentType: false,
    enctype: "multipart/form-data",
    data: data,
    success: function (response) {
      if (response.status === "success") {
        $('.popup__success').show();
      }
    },
    error: function (textStatus) {
      console.log(textStatus);
    },
  });
}
